import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`19th August 2021`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A new module `}<inlineCode parentName="p">{`armeria-sangria`}</inlineCode>{` that integrates with `}<a parentName="p" {...{
            "href": "https://sangria-graphql.github.io"
          }}>{`Sangria GraphQL`}</a>{` is
introduced to let you serve a GraphQL request in Scala. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3703"
          }}>{`#3703`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3704"
          }}>{`#3704`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-scala"
          }}>{`val schema: Schema[CharacterRepo, Unit] = Schema(Query)
Server.builder()
      .service("/graphql",
          SangriaGraphqlService.builder(schema, new CharacterRepo)
                               .enableTracing(true)
                               .maxQueryDepth(10)
                               .build())
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now configure `}<a parentName="p" {...{
            "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
          }}>{`type://WebClient`}</a>{` to automatically follow redirections. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2489"
          }}>{`#2489`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3641"
          }}>{`#3641`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`WebClient.builder()
         .followRedirects()
         .build();
// Customize redirection policy
RedirectConfig config = RedirectConfig.builder()
                                      .maxRedirects(10)
                                      .allownDomains("foo.com", "bar.com")
                                      .build();
WebClient.builder("https://example.com")
         .followRedirects(config)
         .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now recover a failed `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{` with a fallback `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{`. It would be useful
for handling an error of `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{` in a decorator. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3674"
          }}>{`#3674`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpResponse response = 
    HttpResponse.ofFailure(new IllegalStateException("Oops..."));
// The failed HttpResponse will be recovered by the fallback function.
HttpResponse recovered = 
    response.recover(cause -> HttpResponse.of("Fallback"));
assert recovered.aggregate().join().contentUtf8().equals("Fallback");
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now resume a failed `}<a parentName="p" {...{
            "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
          }}>{`type://StreamMessage`}</a>{` with a fallback `}<a parentName="p" {...{
            "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
          }}>{`type://StreamMessage`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3674"
          }}>{`#3674`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`DefaultStreamMessage<Integer> stream = new DefaultStreamMessage<>();
stream.write(1);
stream.write(2);
stream.close(new IllegalStateException("Oops..."));
StreamMessage<Integer> resumed = 
    stream.recoverAndResume(cause -> StreamMessage.of(3, 4));
assert resumed.collect().join().equals(List.of(1, 2, 3, 4));
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now transform an error of `}<a parentName="p" {...{
            "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
          }}>{`type://StreamMessage`}</a>{` into another using
`}<a parentName="p" {...{
            "href": "type://StreamMessage#mapError(Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#mapError(java.util.function.Function)"
          }}>{`type://StreamMessage#mapError(Function)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3668"
          }}>{`#3668`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`StreamMessage stream = 
    StreamMessage.aborted(ClosedStreamException.get());
StreamMessage transformed = stream.mapError(ex -> {
  if (ex instanceof ClosedStreamException) {
    return new IllegalStateException(ex);
  } else {
    return ex;
  }
}); 
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now automatically encode an object into JSON for a response payload using
`}<a parentName="p" {...{
            "href": "type://HttpResponse#ofJson(Object):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#ofJson(java.lang.Object)"
          }}>{`type://HttpResponse#ofJson(Object)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3662"
          }}>{`#3662`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`MyObject myObject = ...;
HttpResponse.ofJson(myObject);
MyError myError = ...;
HttpResponse.ofJson(HttpStatus.INTERNAL_SERVER_ERROR, myError);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now fluently inject `}<inlineCode parentName="p">{`io.grpc.ServerInterceptor`}</inlineCode>{`s using
`}<a parentName="p" {...{
            "href": "type://GrpcServiceBuilder#intercept(Iterable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html#intercept(java.lang.Iterable)"
          }}>{`type://GrpcServiceBuilder#intercept(Iterable)`}</a>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GrpcService.builder()
           .addService(myService)
           .intercept(myInterceptror)
           .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now easily add `}<inlineCode parentName="p">{`Accept`}</inlineCode>{` headers with `}<a parentName="p" {...{
            "href": "type://RequestHeadersBuilder#accept(Iterable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestHeadersBuilder.html#accept(java.lang.Iterable)"
          }}>{`type://RequestHeadersBuilder#accept(Iterable)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3704"
          }}>{`#3704`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`RequestHeaders.builder()
              .accept(MediaType.JSON)
              .accept(MediaType.PLAIN_TEXT);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now initiate graceful connection shutdown using
`}<a parentName="p" {...{
            "href": "type://ServiceRequestContext#initiateConnectionShutdown():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#initiateConnectionShutdown()"
          }}>{`type://ServiceRequestContext#initiateConnectionShutdown()`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3516"
          }}>{`#3516`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3715"
          }}>{`#3715`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify the default `}<inlineCode parentName="p">{`ObjectMapper`}</inlineCode>{` using `}<a parentName="p" {...{
            "href": "type://JacksonObjectMapperProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/JacksonObjectMapperProvider.html"
          }}>{`type://JacksonObjectMapperProvider`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3728"
          }}>{`#3728`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`public class MyObjectMapperProvider 
    implements JacksonObjectMapperProvider {

  @Override
  public ObjectMapper newObjectMapper() {
    return JsonMapper
       .builder()
       .addModules(new KotlinModule())
       .enable(DeserializationFeature.FAIL_ON_NULL_FOR_PRIMITIVES)
       .build();
  }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now serve `}<inlineCode parentName="p">{`application/grpc-web+proto`}</inlineCode>{` and `}<inlineCode parentName="p">{`application/grpc-web-text+proto`}</inlineCode>{`
`}<a parentName="p" {...{
            "href": "https://github.com/grpc/grpc/blob/master/doc/PROTOCOL-WEB.md#protocol-differences-vs-grpc-over-http2"
          }}>{`protocols`}</a>{`
using `}<a parentName="p" {...{
            "href": "type://AbstractUnaryGrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/protocol/AbstractUnaryGrpcService.html"
          }}>{`type://AbstractUnaryGrpcService`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3638"
          }}>{`#3638`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3716"
          }}>{`#3716`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`gRPC trailers are available in the `}<a parentName="p" {...{
            "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
          }}>{`type://RequestContext`}</a>{` for unary gRPC server and client. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3724"
          }}>{`#3724`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3739"
          }}>{`#3739`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`UnaryGrpcClient client = ...;
try (ClientRequestContextCaptor captor = Clients.newContextCaptor()) {
    client.execute("/com.example.MyService/UnaryCall", request); 
    ClientRequestContext ctx = captor.get();
    HttpHeaders trailers = GrpcWebTrailers.get(ctx); // 👈👈👈
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now compress and decompress content with `}<a parentName="p" {...{
            "href": "https://www.brotli.org/"
          }}>{`Brotli`}</a>{` when using
`}<a parentName="p" {...{
            "href": "type://EncodingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/encoding/EncodingService.html"
          }}>{`type://EncodingService`}</a>{`, `}<a parentName="p" {...{
            "href": "type://DecodingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/encoding/DecodingService.html"
          }}>{`type://DecodingService`}</a>{` and `}<a parentName="p" {...{
            "href": "type://DecodingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/encoding/DecodingClient.html"
          }}>{`type://DecodingClient`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3544"
          }}>{`#3544`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3686"
          }}>{`#3686`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now create an `}<a parentName="p" {...{
            "href": "type://HttpResponseException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpResponseException.html"
          }}>{`type://HttpResponseException`}</a>{` with a cause. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3674"
          }}>{`#3674`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now easily capture `}<a parentName="p" {...{
            "href": "typeplural://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
          }}>{`typeplural://ServiceRequestContext`}</a>{` using `}<a parentName="p" {...{
            "href": "type://ServerExtension:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/ServerExtension.html"
          }}>{`type://ServerExtension`}</a>{` in tests. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3648"
          }}>{`#3648`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now suppress the inconsistent Netty version warning by specifying
`}<inlineCode parentName="p">{`-Dcom.linecorp.armeria.warnNettyVersions=false`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3572"
          }}>{`#3572`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3766"
          }}>{`#3766`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "-documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-documentation",
        "aria-label": " documentation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📃 Documentation`}</h2>
    <ul>
      <li parentName="ul">{`You can now learn how to write a REST service with Armeria by walking through the
`}<a parentName="li" {...{
          "href": "/tutorials"
        }}>{`brand-new tutorials`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3420"
        }}>{`#3420`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Special thanks to `}<a parentName="li" {...{
              "href": "https://github.com/freevie"
            }}><strong parentName="a">{`@freevie`}</strong></a>{` who volunteered for this.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HttpHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpHeaders.html"
        }}>{`type://HttpHeaders`}</a>{` now properly caches well-known HTTP headers' value objects. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3711"
        }}>{`#3711`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3714"
        }}>{`#3714`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ConcurrencyLimitTimeoutException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/limit/ConcurrencyLimitTimeoutException.html"
        }}>{`type://ConcurrencyLimitTimeoutException`}</a>{` is now raised when a request times out
in `}<a parentName="li" {...{
          "href": "type://ConcurrencyLimitingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/limit/ConcurrencyLimitingClient.html"
        }}>{`type://ConcurrencyLimitingClient`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3681"
        }}>{`#3681`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://OAuth2AuthorizationGrant:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/auth/oauth2/OAuth2AuthorizationGrant.html"
        }}>{`type://OAuth2AuthorizationGrant`}</a>{` no longer uses locks for better performance. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3571"
        }}>{`#3571`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3618"
        }}>{`#3618`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`You no longer see an `}<inlineCode parentName="li">{`IllegalArgumentException`}</inlineCode>{` when `}<a parentName="li" {...{
          "href": "type://HttpRequest#aggregate():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html#aggregate()"
        }}>{`type://HttpRequest#aggregate()`}</a>{`
and `}<a parentName="li" {...{
          "href": "type://HttpResponse#aggregate():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#aggregate()"
        }}>{`type://HttpResponse#aggregate()`}</a>{` fail with an exception. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3676"
        }}>{`#3676`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3684"
        }}>{`#3684`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3687"
        }}>{`#3687`}</a></li>
      <li parentName="ul">{`You no longer see an incomplete `}<a parentName="li" {...{
          "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
        }}>{`type://RequestLog`}</a>{` when an `}<a parentName="li" {...{
          "href": "type://HttpStatusException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpStatusException.html"
        }}>{`type://HttpStatusException`}</a>{`
is raised. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3719"
        }}>{`#3719`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3674"
        }}>{`#3674`}</a></li>
      <li parentName="ul">{`You no longer see a `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` when an HTTP/1 connection of a client is closed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3729"
        }}>{`#3729`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3747"
        }}>{`#3747`}</a></li>
      <li parentName="ul">{`You no longer see a `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` when a `}<a parentName="li" {...{
          "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
        }}>{`type://StreamMessage`}</a>{` is aborted. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3731"
        }}>{`#3731`}</a></li>
      <li parentName="ul">{`No more unintended leakage of sensitive information when logging a `}<a parentName="li" {...{
          "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
        }}>{`type://RequestLog`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3758"
        }}>{`#3758`}</a></li>
      <li parentName="ul">{`A request is now correctly reset when `}<a parentName="li" {...{
          "href": "type://ServiceRequestContext#cancel():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#cancel()"
        }}>{`type://ServiceRequestContext#cancel()`}</a>{` is called. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3674"
        }}>{`#3674`}</a></li>
      <li parentName="ul">{`You no longer see a `}<inlineCode parentName="li">{`ClassNotFoundException`}</inlineCode>{` in the shaded fastutil. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3713"
        }}>{`#3713`}</a></li>
      <li parentName="ul">{`Armeria now accepts harmless yet illegal characters in a request path for HTTP/1 connections, like it did for
HTTP/2. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3778"
        }}>{`#3778`}</a></li>
      <li parentName="ul">{`You no longer see an `}<a parentName="li" {...{
          "href": "type://EmptyEndpointGroupException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EmptyEndpointGroupException.html"
        }}>{`type://EmptyEndpointGroupException`}</a>{` when `}<a parentName="li" {...{
          "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`typeplural://Endpoint`}</a>{` are completely switched
to new `}<a parentName="li" {...{
          "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`typeplural://Endpoint`}</a>{` in `}<a parentName="li" {...{
          "href": "type://HealthCheckedEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/healthcheck/HealthCheckedEndpointGroup.html"
        }}>{`type://HealthCheckedEndpointGroup`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3637"
        }}>{`#3637`}</a></li>
      <li parentName="ul">{`You can now correctly monitor a `}<a parentName="li" {...{
          "href": "type://BlockingTaskExecutor:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/BlockingTaskExecutor.html"
        }}>{`type://BlockingTaskExecutor`}</a>{` with Micrometer. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3710"
        }}>{`#3710`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3718"
        }}>{`#3718`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://BlockingTaskExecutor:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/BlockingTaskExecutor.html"
        }}>{`type://BlockingTaskExecutor`}</a>{`'s idle threads are now properly terminated after timeout. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3718"
        }}>{`#3718`}</a></li>
      <li parentName="ul">{`You no longer see a `}<inlineCode parentName="li">{`ClassNotFoundException`}</inlineCode>{` when a `}<a parentName="li" {...{
          "href": "type://TransportType:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/TransportType.html"
        }}>{`type://TransportType`}</a>{` is initialized. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3717"
        }}>{`#3717`}</a></li>
      <li parentName="ul">{`The second `}<a parentName="li" {...{
          "href": "type://RetryRuleWithContent:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRuleWithContent.html"
        }}>{`type://RetryRuleWithContent`}</a>{` is now properly applied to decide to retry or not when
using `}<a parentName="li" {...{
          "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
        }}>{`type://RetryingClient`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3720"
        }}>{`#3720`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`requestDurationNanos`}</inlineCode>{`, `}<inlineCode parentName="li">{`responseDurationNanos`}</inlineCode>{` and `}<inlineCode parentName="li">{`totalDurationNanos`}</inlineCode>{` of a child log are properly
calculated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3749"
        }}>{`#3749`}</a></li>
      <li parentName="ul">{`An `}<inlineCode parentName="li">{`IllegalArgumentException`}</inlineCode>{` raised in annotated service will be correctly logged by
`}<a parentName="li" {...{
          "href": "type://LoggingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/LoggingService.html"
        }}>{`type://LoggingService`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3674"
        }}>{`#3674`}</a></li>
      <li parentName="ul">{`You no longer see `}<a parentName="li" {...{
          "href": "type://ClosedSessionException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ClosedSessionException.html"
        }}>{`type://ClosedSessionException`}</a>{` when a large content is sent to an invalid path. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3674"
        }}>{`#3674`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ServerBuilder#localPort(int,Iterable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#localPort(int,java.lang.Iterable)"
        }}>{`type://ServerBuilder#localPort(int,Iterable)`}</a>{` correctly binds both IPv4 and IPv6
loopback addresses. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3725"
        }}>{`#3725`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3726"
        }}>{`#3726`}</a></li>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`Publisher`}</inlineCode>{` or a `}<inlineCode parentName="li">{`CompletableFuture`}</inlineCode>{` returned by a service now properly gets a cancellation signal when the
request is cancelled. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3690"
        }}>{`#3690`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3691"
        }}>{`#3691`}</a></li>
      <li parentName="ul">{`Brave's `}<inlineCode parentName="li">{`ScopeDecorator`}</inlineCode>{`s now correctly propagates a decorated `}<inlineCode parentName="li">{`Scope`}</inlineCode>{` context when
using `}<a parentName="li" {...{
          "href": "type://BraveService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/brave/BraveService.html"
        }}>{`type://BraveService`}</a>{` and `}<a parentName="li" {...{
          "href": "type://BraveClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/brave/BraveClient.html"
        }}>{`type://BraveClient`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3408"
        }}>{`#3408`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3430"
        }}>{`#3430`}</a></li>
      <li parentName="ul">{`A `}<a parentName="li" {...{
          "href": "type://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
        }}>{`type://Server`}</a>{` in Spring integration is now automatically started by `}<inlineCode parentName="li">{`SmartLifecycle`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3759"
        }}>{`#3759`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3762"
        }}>{`#3762`}</a></li>
      <li parentName="ul">{`You no longer see `}<a parentName="li" {...{
          "href": "type://CancelledSubscriptionException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/CancelledSubscriptionException.html"
        }}>{`type://CancelledSubscriptionException`}</a>{` when a WebFlux's `}<inlineCode parentName="li">{`WebClient`}</inlineCode>{` receives an unexpected
`}<inlineCode parentName="li">{`Content-Type`}</inlineCode>{` header. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3730"
        }}>{`#3730`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3750"
        }}>{`#3750`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://TomcatService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/tomcat/TomcatService.html"
        }}>{`type://TomcatService`}</a>{` now properly handles an exception with Spring Web's `}<inlineCode parentName="li">{`ExceptionHandler`}</inlineCode>{`s. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3447"
        }}>{`#3447`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3732"
        }}>{`#3732`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`ErrorWebExceptionHandler`}</inlineCode>{` in WebFlux converts an exception to the response correctly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3779"
        }}>{`#3779`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://JettyService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/jetty/JettyService.html"
        }}>{`type://JettyService`}</a>{` doesn't raise an `}<inlineCode parentName="li">{`EofException`}</inlineCode>{` anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3688"
        }}>{`#3688`}</a></li>
      <li parentName="ul">{`Kotlin `}<a parentName="li" {...{
          "href": "https://kotlinlang.org/docs/java-interop.html#compiler-configuration"
        }}><inlineCode parentName="a">{`jsr305=strict`}</inlineCode></a>{` option no
longer raises a false positive error when a null value is returned from where nulls are allowed
to return. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3751"
        }}>{`#3751`}</a></li>
      <li parentName="ul">{`You no longer see null values when a case class is decoded from a JSON when using annotated services with
Scala. 400 Bad Request will be returned instead. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3728"
        }}>{`#3728`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`-Dcom.linecorp.armeria.annotatedServiceExceptionVerbosity`}</inlineCode>{` and `}<a parentName="li" {...{
          "href": "type://ExceptionVerbosity:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ExceptionVerbosity.html"
        }}>{`type://ExceptionVerbosity`}</a>{` has been
deprecated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3674"
        }}>{`#3674`}</a>
        <ul parentName="li">
          <li parentName="ul">{`You can use `}<a parentName="li" {...{
              "href": "type://LoggingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/LoggingService.html"
            }}>{`type://LoggingService`}</a>{` that automatically logs exceptions using for your convenience; or
manually log exceptions using `}<a parentName="li" {...{
              "href": "type://ServerBuilder#exceptionHandler(ExceptionHandler)"
            }}>{`type://ServerBuilder#exceptionHandler(ExceptionHandler)`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ObservableResponseConverterFunction#%3Cinit%3E(ResponseConverterFunction,ExceptionHandlerFunction):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/rxjava2/ObservableResponseConverterFunction.html#%3Cinit%3E(com.linecorp.armeria.server.annotation.ResponseConverterFunction,com.linecorp.armeria.server.annotation.ExceptionHandlerFunction)?full"
        }}>{`type://ObservableResponseConverterFunction#`}{`<`}{`init`}{`>`}{`(ResponseConverterFunction,ExceptionHandlerFunction)?full`}</a>{`
has been deprecated in favor of
`}<a parentName="li" {...{
          "href": "type://ObservableResponseConverterFunction#%3Cinit%3E(ResponseConverterFunction):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/rxjava2/ObservableResponseConverterFunction.html#%3Cinit%3E(com.linecorp.armeria.server.annotation.ResponseConverterFunction)?full"
        }}>{`type://ObservableResponseConverterFunction#`}{`<`}{`init`}{`>`}{`(ResponseConverterFunction)?full`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3674"
        }}>{`#3674`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HttpResponseException#of(HttpStatus):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpResponseException.html#of(com.linecorp.armeria.common.HttpStatus)?full"
        }}>{`type://HttpResponseException#of(HttpStatus)?full`}</a>{` and `}<a parentName="li" {...{
          "href": "type://HttpResponseException#of(int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpResponseException.html#of(int)?full"
        }}>{`type://HttpResponseException#of(int)?full`}</a>{` has been
deprecated in favor of `}<a parentName="li" {...{
          "href": "type://HttpStatusException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpStatusException.html"
        }}>{`type://HttpStatusException`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3674"
        }}>{`#3674`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://ExceptionHandler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ExceptionHandler.html"
          }}>{`type://ExceptionHandler`}</a>{` now returns `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{` instead of `}<a parentName="p" {...{
            "href": "type://AggregatedHttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/AggregatedHttpResponse.html"
          }}>{`type://AggregatedHttpResponse`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3674"
          }}>{`#3674`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Before:
ExceptionHandler handler = (ctx, cause) -> {
    if (cause instanceof IllegalArgumentException) {
        return AggregatedHttpResponse.of(HttpStatus.BAD_REQUEST);
    }
    return null;
}
  
// After:
ExceptionHandler handler = (ctx, cause) -> {
    if (cause instanceof IllegalArgumentException) {
        return HttpResponse.of(HttpStatus.BAD_REQUEST); // 👈👈👈
    }
    return null;
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
          }}>{`type://RequestContext`}</a>{` is added as the first parameter of `}<a parentName="p" {...{
            "href": "type://GrpcStatusFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/GrpcStatusFunction.html"
          }}>{`type://GrpcStatusFunction`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3692"
          }}>{`#3692`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3693"
          }}>{`#3693`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Before:
GrpcService.builder()
           .exceptionMapping((throwable, metadata) -> {
               if (throwable instanceof IllegalArgumentException) {
                   return Status.INVALID_ARGUMENT;
               }
               return null;
           });

// After:
GrpcService.builder()
           .exceptionMapping((ctx, throwable, metadata) -> { // 👈👈👈
               if (throwable instanceof IllegalArgumentException) {
                   return Status.INVALID_ARGUMENT;
               }
               return null;
            });
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`JacksonModuleProvider`}</inlineCode>{` has been removed in favor of `}<a parentName="p" {...{
            "href": "type://JacksonObjectMapperProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/JacksonObjectMapperProvider.html"
          }}>{`type://JacksonObjectMapperProvider`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`ResponseConverterFunctionProvider.createResponseConverterFunction(Type,ResponseConverterFunction,ExceptionHandlerFunction)`}</inlineCode>{`
has been removed in favor of
`}<a parentName="p" {...{
            "href": "type://ResponseConverterFunctionProvider#createResponseConverterFunction(Type,ResponseConverterFunction)?full"
          }}>{`type://ResponseConverterFunctionProvider#createResponseConverterFunction(Type,ResponseConverterFunction)?full`}</a>{`
. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3674"
          }}>{`#3674`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`OAuth2AuthorizationGrant.withAuthorization()`}</inlineCode>{` has been removed. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3618"
          }}>{`#3618`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Curator 5.1.0 → 5.2.0`}</li>
      <li parentName="ul">{`Dropwizard 2.0.23 → 2.0.24`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.2.2 → 4.2.3`}</li>
      <li parentName="ul">{`Jackson 2.12.3 → 2.12.4`}</li>
      <li parentName="ul">{`Jetty 9.4.42 → 9.4.23`}</li>
      <li parentName="ul">{`GraphQL-Java 16.2 → 17.1`}</li>
      <li parentName="ul">{`gRPC-Java 1.38.1 → 1.40.0`}</li>
      <li parentName="ul">{`Kotlin 1.5.10 → 1.5.21`}</li>
      <li parentName="ul">{`kotlinx-coroutines-core 1.5.0 → 1.5.1`}</li>
      <li parentName="ul">{`Logback 1.2.3 → 1.2.5`}</li>
      <li parentName="ul">{`Micrometer 1.7.1 → 1.7.2`}</li>
      <li parentName="ul">{`Netty 4.1.65 → 4.1.66`}</li>
      <li parentName="ul">{`netty-tcnative-boringssl-static 2.0.39 → 2.0.40`}</li>
      <li parentName="ul">{`netty-transport-native-io_uring 0.0.5 → 0.0.8`}</li>
      <li parentName="ul">{`java-jwt 3.16.0 → 3.18.1`}</li>
      <li parentName="ul">{`protobuf-java 3.12.0 → 3.17.2`}</li>
      <li parentName="ul">{`Reactor 3.4.7 → 3.4.9`}</li>
      <li parentName="ul">{`RESTeasy 4.6.1 → 4.7.1`}</li>
      <li parentName="ul">{`RxJava 3.0.13 → 3.1.0`}</li>
      <li parentName="ul">{`scala-collection-compat 2.4.4 → 2.5.0`}</li>
      <li parentName="ul">{`ScalaPB 0.11.4 → 0.11.5`}</li>
      <li parentName="ul">{`ScalaPB json4s 0.11.1 → 0.12.0`}</li>
      <li parentName="ul">{`SLF4J 1.7.31 → 1.7.32`}</li>
      <li parentName="ul">{`Spring 5.3.8 → 5.3.9`}</li>
      <li parentName="ul">{`Spring Boot 2.5.2 → 2.5.3`}</li>
      <li parentName="ul">{`ZooKeeper 3.6.2 → 3.6.3`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'alexc-db', 'freevie', 'ghkim3221', 'heowc', 'heka1024', 'icepeppermint', 'imasahiro', 'jrhee17', 'jupiny', 'karellen-kim', 'kezhenxu94', 'kojilin', 'ks-yim', 'ikhoon', 'minwoox', 'mauhiz', 'mynameisjwlee', 'sfc-gh-pbennes', 'tobias-', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      